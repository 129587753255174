import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Link, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Надійний Дім - Ваша агенція нерухомості
			</title>
			<meta name={"description"} content={"Надійний Дім - професійна допомога в купівлі, продажу та оренді нерухомості. Забезпечуємо якісні послуги для вашого комфорту."} />
			<meta property={"og:title"} content={"Надійний Дім - Ваша агенція нерухомості"} />
			<meta property={"og:description"} content={"Надійний Дім - професійна допомога в купівлі, продажу та оренді нерухомості. Забезпечуємо якісні послуги для вашого комфорту."} />
			<meta property={"og:image"} content={"https://worldwisd.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://worldwisd.com/img/6835685.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://worldwisd.com/img/6835685.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://worldwisd.com/img/6835685.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://worldwisd.com/img/6835685.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://worldwisd.com/img/6835685.png"} />
			<meta name={"msapplication-TileImage"} content={"https://worldwisd.com/img/6835685.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-16">
			<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
			<Box
				width="100%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box width="100%" overflow-x="hidden" overflow-y="hidden">
					<Image src="https://worldwisd.com/img/1.jpg" width="100%" border-radius="25px" />
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				justify-content="space-between"
				width="38%"
				flex-direction="row"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				lg-justify-content="center"
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box display="flex" flex-direction="column" md-width="100%">
					<Text
						font="normal 900 55px/1.2 --fontFamily-sansVerdana"
						lg-text-align="center"
						sm-font="--headline3"
						sm-margin="0px 0px 20px 0px"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						md-text-align="left"
					>
						Надійний Дім - Ваша агенція нерухомості
					</Text>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				lg-order="1"
				display="flex"
				width="60%"
				flex-direction="row"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="space-between"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-padding="0px 16px 16px 16px"
			>
				<Box display="flex" flex-direction="column">
					<Text
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						lg-text-align="center"
						md-text-align="left"
					>
						Вітаємо вас на сайті агенції нерухомості Надійний Дім. Ми спеціалізуємося на купівлі, продажу та оренді житлової та комерційної нерухомості. Наша команда професіоналів забезпечить вам найвищий рівень обслуговування та допоможе знайти ідеальне житло або офіс для вашого бізнесу.
					</Text>
				</Box>
			</Box>
			<Box text-align="center" width="100%" margin="40px 0px 20px 0px">
				<Link
					href="/contact"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
					target="_blank"
				>
					Зв'яжіться з нами
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				lg-display="flex"
				lg-justify-content="center"
				padding="0px 16px 0px 0px"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://worldwisd.com/img/2.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="20px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				lg-width="100%"
				lg-padding="24px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="normal 900 38px/1.2 --fontFamily-sans"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Чому обирають нас
				</Text>
				<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				Наші клієнти довіряють нам з багатьох причин:
					<br />
					<br />
					- Висока професійність
					<br />
					<br />
					- Індивідуальний підхід до кожного клієнта
					<br />
					<br />
					- Широка база об'єктів нерухомості
					<br />
					<br />
					- Чесність та прозорість угод
					<br />
					<br />
					- Комплексна підтримка на всіх етапах угоди.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					<Strong>
					Зв'яжіться з нами вже сьогодні!
					</Strong>
					<br />
					<br />
					Не втрачайте можливість скористатися професійною допомогою від Надійний Дім. Наші експерти готові допомогти вам знайти ідеальну нерухомість або вигідно продати вашу власність.
				</Text>
				<Link
					href="/contact"
					padding="12px 40px 12px 40px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
					target="_blank"
					align-self="center"
				>
					Зв'язатися зараз
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://worldwisd.com/img/3.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65ccedc78e2e8e0021782120"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});